.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 1em 5px 1em;
  margin-bottom: 10px;
  border-radius: 4px;
}
.textFieldMinHeight {
  min-height: 70px;
}
.error {
  color: red;
  font-size: 13px;
}
.gridFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 2em;
  .saveButton {
    align-self: center;
    margin-left: 40px;
    width: 170px;
  }
}
.propertyContainer {
  min-height: 55px;
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  padding: 1em;
  margin-top: -2em;
  .autoComplete {
    width: 100%;
    background-color: #f4f6f8;
  }
}
