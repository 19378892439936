.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  border-bottom: 1px solid #e4e4e4;
  height: 5em;
  justify-content: center;
}
.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
  .postsContainer {
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    z-index: 100;
    .postCardContent {
      max-height: 11em;
      padding-top: 0;
      .postCreateCard {
        display: flex;
        flex: 3;
        align-items: center;
        cursor: pointer;
        height: 50px;
        margin: 10px 0 -6px 0;
      }
    }
  }
  .autoComplete {
    margin-left: 2em;
    width: 20em;
  }
  .post {
    margin-bottom: 1em;
    border-radius: 0 0 4px 4px;
  }
  .postHeader {
    margin-bottom: 2em;
  }
  .addPostActionsContainer {
    display: flex;
  }
}
.dialogActions {
  padding: 0 8px;
  justify-content: flex-start;
}
.formControlContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  max-width: 25em;
  min-width: 60em;
}
.postButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 14px 24px;
}
.noTextTransformButton {
  text-transform: none !important;
  color: #757575;
}
.userInfo {
  max-width: 30em;
  min-width: 25em;
  right: 0;
  position: fixed;
  height: 35em;
}
.emptyStateContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .emptyStateImg {
    max-width: 15em;
  }
}
