.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  margin-top: 1em;
}
.flexCenter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2em;
  .root {
    min-width: 275px;
    margin-right: 30px;
    margin-bottom: 1em;
    &:hover {
      box-shadow: 3px 3px 5px 1px #e0e0e0;
      cursor: pointer;
    }
  }
  .sideCardsContainer {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 21em;
    .sideCard {
      margin-bottom: 1em;
      display: flex;
      align-items: center;
      .sideCardContent {
        padding: 10px;
        width: 100%;
      }
      .sideButton {
        font-size: 30px;
        color: rgb(48, 63, 81);
        margin-left: 5px;
      }
      &:hover {
        box-shadow: 3px 3px 5px 1px #e0e0e0;
        cursor: pointer;
      }
    }
  }

  .cardHeader {
    display: flex;
    justify-content: flex-end;
    height: 30px;
    align-items: center;
    padding: 5px;
  }
  .cardContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 5px 30px;
    .tenantIcon {
      font-size: 50px;
      color: #00b894;
    }
    .unitIcon {
      font-size: 50px;
      color: #4285f4;
    }
    .propertyIcon {
      font-size: 50px;
      color: #e17055;
    }
    .itemsCount {
      font-weight: 500;
    }
    .subtitle {
      font-size: 17px;
      color: gray;
      font-family: "Poppins-SemiBold";
    }
  }
  .cardsSkeleton {
    margin: 0 1em 1em 0;
  }
  .emptyTenantsState {
    display: flex;
    align-items: center;
    padding: 1em;
  }
}
