@font-face {
  font-family: "Poppins";
  src: url('../assets//Fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url('../assets//Fonts/Poppins-SemiBold.ttf');
}
@font-face {
  font-family: "Poppins-Black";
  src: url("../assets/Fonts/Poppins-Black.ttf");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("../assets/Fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("../assets/Fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: "Poppins-Light";
  src: url("../assets/Fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: "Poppins-Thin";
  src: url("../assets/Fonts/Poppins-Thin.ttf");
}