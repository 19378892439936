.header {
  display: flex;
  border-bottom: 1px solid #e4e4e4;
  height: 5em;
  flex-direction: column;
  justify-content: center;
}
.documentsListContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 2em 0;
}
.fileUploadContainer {
  width: 100%;
  background-color: #fafafa;
  height: 31em;
  .dropZone {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bdbdbd;
    margin: 1em 0;
    border-style: dashed;
    border-color: #eeeeee;
    transition: border 0.4s;
    outline: none;
    &:hover {
      border-color: #2093e6;
    }
    cursor: pointer;
    .fileUploadInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
.emptyStateContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 17em;
}
.warningStyle {
  color: #ed9231 !important;
  border-color: #ed9231 !important;
}
.incompleteDocumentInfo {
  color: #ed9231;
  display: flex;
  align-items: center;
}
.downloadIcon {
  margin-top: 20px;
  border-radius: 50%;
  font-size: 50px !important;
  width: 100%;
  height: 100%;
  animation: bounce 0.2s infinite alternate;
  -webkit-animation: bounce 0.8s infinite alternate;
}
@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}
