.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 0 5px 0;
  margin-bottom: 10px;
  border-radius: 4px;
}
.container {
  margin-top: 10px;
  .propertyFormContainer {
    max-width: 45%;
    padding-right: 0;
    margin-top: 10px;
  }
  .tenantsFormContainer {
    min-width: 54%;
    padding-left: 0;
  }
}
