.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 1em 5px 1em;
  margin-bottom: 10px;
  border-radius: 4px;
}
.listItem {
  background-color: #fafafa;
  margin-bottom: 1em;
  display: flex !important;
  justify-content: space-between !important;
}
.icon {
  color: #303f51;
}
