.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  height: 5em;
}
.tableCell {
  max-width: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.emptyStateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 35em;
  .emptyStateImg {
    max-width: 15em;
  }
  .emptyStateText {
    margin: 0.5em 0;
  }
  .emptyStateBtn {
    width: 19em;
  }
}
