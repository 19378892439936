// Global scss
@import "./components/fonts";

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: #fafafa !important;
}
// override MUI styles to our needs
.MuiBadge-badge {
  height: 15px !important;
  min-width: 15px !important;
  width: 15px;
}
.MuiBadge-anchorOriginBottomRightRectangle {
  right: 2px !important;
  bottom: 4px !important;
}
.MuiTabs-root {
  background-color: #f8f8f8 !important;
}
.MuiCardHeader-content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.MuiTypography-body1 {
  font-family: "Poppins" !important;
  font-size: 14px !important;
}
.MuiPaper-elevation1 {
  box-shadow: -1px 1px 9px -15px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.1) !important;
}
