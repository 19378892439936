.container {
  color: #303f51;
  text-transform: uppercase;
  .listItem {
    padding: 1em 2em;
  }
  .activeListItem {
    border-left: 4px solid #304052;
    background-color: #e0e0e08c;
    padding: 1em 2em;
    padding-left: calc(2em - 4px);
  }
  .innerListContainer {
    border-left: 4px solid #304052;
    .innerListItem {
      padding-left: 3em;
    }
    .innerListItemActive {
      background-color: #f4f6f8ad;
      padding-left: 3em;
    }
  }
}
.image {
  width: 27px;
  height: 27px;
}

.innerImage {
  max-width: 20px;
}
