.root {
  min-width: 275px;
  margin-right: 1em;
  margin-bottom: 1em;
  &:hover {
    box-shadow: 3px 3px 5px 1px #e0e0e0;
    cursor: pointer;
  }
}
.tenantCardContent {
  width: 32em;
  padding: 2em;
  .cardRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cardText {
      width: 33.33%;
      text-align: start;
      margin-right: 1em;
    }
    .boldText {
      color: #303f51c2;
      font-weight: bold;
      width: 33.33%;
      text-align: start;
      margin-right: 1em;
    }
  }
}
