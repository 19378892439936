.post{
	margin-bottom: 1em;
	border-radius: 0 0 4px 4px;
	.postContentContaienr {
		min-height: 5em;
	}
	.postReactionsContainer {
		display: flex;
		padding: 5px 16px;
		align-items: center;
		.postReactionItem { 
			display: flex;
			align-items: center;
			margin-right: 1em;
			.likeFab {
				width: 23px !important;
				min-height:20px;
				height: 20px;
				margin-right: 3px;
				box-shadow: none;
				.cardActionsIcon {
				  font-size: 13px;
				}
			}
		}
  }
} 
.postHeader{ 
	margin-bottom: 2em;
}
.addPostActionsContainer {
	display:flex
  }
.dialogActions {
	padding: 0 8px;
	justify-content: flex-start;
}
.formControlContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 24px;
}
.postButtonContainer{
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding: 14px 24px;	
}
.noTextTransformButton {
	text-transform: none !important;
  color:#757575 ;
}
.postReactionsContainer {
	display: flex;
	padding: 5px 16px;
	align-items: center;
	.postReactionItem { 
		display: flex;
		align-items: center;
		margin-right: 1em;
	}
}
.MuiBadge-badge {
	height:15px;
	width:15px
}
.dialogHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding:  16px 24px ;
}
.commentContainer {
  display: flex;
  align-items: flex-start;
  padding: 16px 0;
  .commentInfo {
     background-color: #F3F6F8;
     border-radius: 0 10px 10px 10px;
     padding: 14px;
		 width: 100%;
		 margin-left: 5px;
  }
}
.activeText {
	color: #2093E6;
}
.commentsWrapper {
  max-height: 16em;
  overflow: auto !important;
}
.collapseContainer {
	padding-bottom: 0 !important;
	padding-top: 0 !important;

}
.pointerCursor {
	cursor: pointer;
}
.postContentContaienr {
	min-height: 5em;
}
.cardActionsIcon {
	font-size: 13px;
}
.scrollView {
  width: 35em;
 max-height: 25em;
 overflow: auto
}

