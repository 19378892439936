.rightButton {
  margin-left: auto !important;
}
.userInfoMenu {
  width: 300px;
  padding: 15px;
  height: 400px;
  .userInfoHeader {
    display: flex;
    align-items: center;
    margin: 15px 0;
    padding-left: 15px;
    .avatar {
      width: 50px;
    height: 50px;
    font-size: 10px;
    background-color: #303F51;
    margin-right: 1rem;
    }
  }
  .organizationInfo {
    display: flex;
    align-items: center;
    padding: 8px 0 8px 15px;
    height: 50px;
  }
  .userInfoFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    padding: 5px 5px;
    width: 90%;
    border-top: 1px solid #e0e0e0;
  }
  .menuIcon {
    margin-right: 5px;
  }
}
.languageImg {
  width: 30px;
  height: 30px;
  margin-right: -8px;
  margin-bottom: -5px;
}
.radioGroup {
  display: flex !important;
  align-items: center !important;
}
.hiddenOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15em;
}
