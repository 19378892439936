.dialogActions {
  padding: 0 8px;
  justify-content: flex-start;
}
.formControlContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  max-width: 25em;
  min-width: 60em;
}
.postButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 14px 24px;
}
