.container{
    height: 100vh;
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.submitButton{
    margin-top: 10px;
}
.card {
    width:30%;
    min-width: 400px;
}
.gridMargin {
    margin-top: 4px !important;
}

@media(max-width: 600px) {
    .card {
        min-width: 70%;
    }
}