.textFieldContainer {
  display: flex;
  flex-direction: column;
}
.textFieldMinHeight {
  min-height: 70px;
}
.addressHeader {
  padding: 1em;
  background-color: #fafafa;
  border-radius: 4px;
}
.addressContainer {
  display: flex;
  width: 100%;
  max-height: 23em;
  animation: fadeIn 1.2s;
  margin-top: 2em;
}
.error {
  color: red;
  font-size: 13px;
}
.typography {
  font-size: 20px !important;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
