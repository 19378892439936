.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .storeImagesContainer {
    display: flex;
    max-height: 5em;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
  }
  .storeImage{
    max-width: 13em;
    margin-left: 2em;
  }
  .allSetImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
     margin-top: 5em;
     width: 100%;
  }
  .userOnboardingContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .headerText {
      margin-top: 2em;
      padding: 0 1em;
      font-family: 'Poppins-Regular';
    }
    .storeImageContainer {
      display: flex;
      justify-content: center;
      align-items: center;
    width: 100%;

    }
    .downloadOptions {
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
      align-items: center;
      width: 100%;
    }
    .qrCodeSection {
      padding:3em
    }
  }
  .allSetImage{ 
    margin-bottom: 1em;
    height: 15em;
  }
  .divider {
    width: 20%;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    line-height: 0.1em;
    margin: 30px 0 30px;
    margin-top: -1em;
    margin-bottom: -1em;
    .dividerSpan{
      padding: 0 10px;
      background-color: #f8f8f8;
      color:gray;
    }
  }
  .qrCodeContainer{
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 0 10px; 
    .qrCodeGuideText {
      font-family: 'Poppins-Light';
      padding: 0 1em;
    }
  
  }
}
.qrCode {
  transition: transform .3s;
  &:hover{ 
   transform: scale(1.5)
 }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
  .downloadOptions {
    flex-direction: column !important;
  }
}


@media only screen and (max-width: 815px) {
  .allSetImage {
    max-width: 20em !important;
  }
  .storeImage{
    max-width: 10em !important;
    margin-left: 2em;
  }
  .storeImageContainer {
    padding-right: 1em;
  }
}

@media only screen and (max-width: 320px) {
  .storeImage{
    max-width: 8em !important;
    margin-left: 2em;
  }
  .qrCodeGuideText {
    display: none
  }
}