.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 0 5px 0;
  margin-bottom: 10px;
  border-radius: 4px;
}
.propertyInfoContainer {
  min-height: 400px;
  padding: 20px;
  display: flex !important;
  justify-content: space-between;
  .overFlow {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    .propertyDescription {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 1em;
    }
    .addressInfo {
      margin-top: 2em;
      max-height: 200px;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
    }
  }
}
