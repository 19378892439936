.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 1em 5px 1em;
  margin-bottom: 10px;
  border-radius: 4px;
}
.textFieldContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  .textFieldMinHeight {
    min-height: 70px;
  }
}
.editFormsContainer {
  display: flex;
  width: 100%;
  margin-top: 10px;
  flex-direction: column;
  max-height: 64em;
}
.error {
  color: red;
  font-size: 13px;
}
.gridFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2em;
  padding-top: 0;
  .saveButton {
    align-self: center;
    margin-left: 40px;
    width: 170px;
  }
}
.propertyContainer {
  min-height: 55px;
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  .autoComplete {
    width: 100%;
    background-color: #f4f6f8;
  }
}
.listItem {
  background-color: #fafafa;
  margin-bottom: 1em;
  display: flex !important;
  justify-content: space-between !important;
}
