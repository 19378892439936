.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 90vh;
  .content {
    display: flex;
    width: 53em;
    flex-direction: column;
    .formHeader {
      margin-bottom: 30px;
      padding: 0 30px;
    }
    .formWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 8em;
    }
    .saveOrganizationButton {
      width: 15em;
    }
  }
}
.gridContent {
  min-height: 34rem;
  padding: 0 40px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
  .addFormsContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 20px;
  }
  .textFieldMinHeight {
    min-height: 70px;
  }
 }
 .error {
  color: red;
  font-size: 13px;
 }
 .saveButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 30px;
 }
