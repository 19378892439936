.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 1em 5px 1em;
  margin-bottom: 10px;
  border-radius: 4px;
}
.textFieldMinHeight {
  min-height: 70px;
}
.error {
  color: red;
  font-size: 13px;
}
.icon {
  min-width: 30px;
}
.gridFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 64px;
  .savePropertyButton {
    align-self: center;
    margin-left: 40px !important;
    width: 170px;
  }
}
