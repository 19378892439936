.container {
  display: flex !important; 
  align-items: center;
  flex-direction: column;
  height: 90vh;
  width: 700px ;
  .content {
    width: 760px;
    padding: 20px;
    margin-bottom: 20px;
    .invitationHeader {
      padding-bottom: 5px;
      border-bottom: 1px solid #e4e4e4e4;
    }
    .invitationContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
      .email {
        width: 500px;
      }
    }
    .invitationFooter {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
