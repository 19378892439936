.dialogWrapper{
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dialogHeader {
  display:flex;
  align-items:center;
  justify-content: center;
}
.dialogContent {
  width:100%;
  display: flex;
  justify-content: center;
  padding: 0 5em;
  flex-direction: column;
  text-align: center;
}
.warningIcon {
  font-size: 5em !important;
  width: 30px;
}
.deleteButton {
  color: #ff726f !important;
  text-transform: none !important;
  margin-right: 5px !important;
  background-color:rgba(0, 0, 0, 0.05) !important;
}
.cancelButton {
  text-transform: none !important;
}
.secondaryText {
  color: #bdbdbd 
}
.dialogActions {
  padding: 16px;
}