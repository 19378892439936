.collapseContainer {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  .commentsWrapper {
    max-height: 16em;
    overflow: auto !important;
    .commentContainer {
      display: flex;
      align-items: flex-start;
      padding: 16px 0;
      .avatar {
        background-color: #303f51;
      }
      .commentInfo {
        background-color: #f3f6f8;
        border-radius: 0 10px 10px 10px;
        padding: 14px;
        width: 100%;
        margin-left: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
