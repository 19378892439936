.textFieldMinHeight {
  min-height: 70px;
}
.error {
  color: red;
  font-size: 13px;
}
.icon {
  min-width:30px;
}
.fileUploadContainer {
  width: 100%;
  .dropZone {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #BDBDBD;
    background-color: #FAFAFA;
    margin-bottom: 1em;
    border-style: dashed;
    border-color: #EEEEEE;
    transition: border 0.4s;
    outline: none;
    cursor: pointer;
    &:hover {
      border-color: #2093E6;
    }
     .fileUploadInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 10em;
      width: 100%;
    }
  }
}
.defaultContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.successStyle {
  color: #00AB66
}
.warningStyle {
 color: #ED9231;
}
.acceptedFilesContainer {
  background-color: #f8f8f8 !important;
}
.cardActions {
  justify-content: flex-end !important;
  padding-right: 0 !important;
  margin-top: 1em !important;
}

