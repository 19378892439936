.container {
  max-width: 550px;
  margin: 0 auto;
  .avatar {
    width: 80px;
    height: 80px;
    font-size: 20px;
    background-color: #303f51;
    margin-bottom: 1rem;
  }
  .cardHeader {
    display: flex;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
